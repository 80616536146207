<i18n>
{
  "en": {
    "movie-count": "Featured movies",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "movie_count": "# Movies",
    "no_movies": "You have no download history"
  },
  "ja": {
    "movie-count": "出演作品数",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "movie_count": "出演作品数",
    "no_movies": "ダウンロード履歴がありません"
  }
}
</i18n>

<template>
<div>
  <!-- no history: show message -->
  <div v-if="historyList.length === 0">
    <div class="meta-data">{{ $t('no_movies') }}</div>
  </div>
  <!-- has history: show movie list -->
  <div v-if="historyList.length > 0" class="ranking ranking-movie">
    <div class="list list--compact">
      <div class="flex-grid">
        <div class="grid-item" v-for="(movie, index) in historyList" :key="movie.MovieID">
          <router-link :to="`/movies/${movie.MovieID}/`" class="entry">
            <div class="entry-media">
              <div v-if="movie.isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
              <div v-if="movie.isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
              <div v-if="movie.isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
              <div class="media-thum">
                <img :src="`${movie.thumbnailSrc}`" @error="useFallbackImage(index)" class="media-thum-image" loading="lazy" />
              </div>
            </div>
            <div class="entry-meta">
              <div v-if="locale == 'ja'">
                <div class="meta-title">{{ movie.Title }}</div>
                <div class="meta-data">{{ movie.Actor }}</div>
              </div>
              <div v-if="locale == 'en'">
                <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title">{{ movie.TitleEn }}</div>
                <div v-else class="meta-title" v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}</div>
              </div>
              <div class="meta-rating"><star-rating v-model="movie.AvgRating" active-color="#FEB507" :increment="0.1" :star-size="14" :inline="true" :show-rating="false" :read-only="true"></star-rating></div>
            </div>
          </router-link>
        </div>
      </div>
    </div><!-- /.list -->
  </div>
</div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */
import axios from 'axios';
import StarRating from 'vue-star-rating';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import Limiter from '@/assets/js/utils/limiters';

export default {
  props: {
    listType: {
      type: String,
      default: 'today',
    },
  },
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      historyList: [],
      posterFallbackTries: {},
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  created() {
    // call DML API and update state values
    Limiter.throttle(axios.get(`${process.env.VUE_APP_DML_API}/json/history`, { withCredentials: true }).then((response) => {
      if (response.data !== null) {
        // dml api json response doesn't contain the 'Downloaded' property if there hasn't been any
        // downloaded movies, so we have to add an extra check to see if the prop exists
        let dmlHistoryList = [];
        if (this.listType === 'yesterday' && Object.prototype.hasOwnProperty.call(response.data.Previous, 'Downloaded')) {
          dmlHistoryList = response.data.Previous.Downloaded;
        } else if (this.listType === 'today' && Object.prototype.hasOwnProperty.call(response.data.Current, 'Downloaded')) {
          dmlHistoryList = response.data.Current.Downloaded;
        }

        // dml api only returns japanese movie data; so, get movie details for each movie in the
        // list, so we can get all the data (english!) that we normally have for movie lists
        const bfAPI = new BifrostAPI();
        if (dmlHistoryList.length > 0) {
          dmlHistoryList.forEach((movie) => {
            bfAPI.getMovieDetail(movie.MovieID).then((result) => {
              const details = MovieHelper.expandDetails(result, this.locale, this.userTypeID);
              this.historyList.push(details);
            });
          });
        }
      }
    }).catch((error) => {
      console.log('%cDML API error', 'background: #222; color: #f00', error);
    }), 5000);
  },
  methods: {
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.historyList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.historyList[index], 'thumbnailSrc', `/assets/sample/${this.historyList[index].MovieID}/list1.jpg`);
        this.posterFallbackTries[this.historyList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.historyList[index], 'thumbnailSrc', '/img/common/now_printing.png');
      }
    },
  },
};
</script>

<style lang="scss">
</style>
