<i18n>
{
  "en": {
    "browser_title": "Download History",
    "tab_today": "Today",
    "tab_yesterday": "Yesterday",
    "dml_title": "# Downloads Remaining",
    "dml_remaining_0": " ",
    "dml_remaining_1": " available",
    "dml_separator": " / ",
    "dml_total_0": " ",
    "dml_total_1": " total",
    "dml_link": "Add More Downloads"
  },
  "ja": {
    "browser_title": "ダウンロード履歴",
    "tab_today": "本日の履歴",
    "tab_yesterday": "前日の履歴",
    "dml_title": "本日のダウンロード",
    "dml_remaining_0": "残り ",
    "dml_remaining_1": " ",
    "dml_separator": " / ",
    "dml_total_0": "全",
    "dml_total_1": "本",
    "dml_link": "ダウンロード本数を追加する"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <div class="contents" v-if="!newXPEligible">
    <div class="heading">
      <h1>{{ $t('browser_title') }}</h1>
    </div>

    <!-- bar graph -->
    <div>
      <dl>
        <dt class="member-info-title">
          {{ $t('dml_title') }}
          <span>{{ $t('dml_total_0') }}{{ slotsTotal }}{{ $t('dml_total_1') }}{{ $t('dml_separator') }}{{ $t('dml_remaining_0') }}{{ slotsFree }}{{ $t('dml_remaining_1') }}</span>
        </dt>
        <dd class="member-info-data">
          <div class="member-info-dml">
            <span class="member-info-dml--used" :style="`width: ${dmlRatio}%;`"></span>
          </div>
        </dd>
        <dd class="member-info-data"><router-link to="/dml/">{{ $t('dml_link') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>
      </dl>
    </div>

    <!-- tabs -->
    <vue-tabs class="tab-scroll" @tab-change="tabChangeHandler">
      <v-tab :title="$t('tab_today')">
        <history-list list-type="today" />
      </v-tab>
      <v-tab :title="$t('tab_yesterday')">
        <history-list list-type="yesterday" />
      </v-tab>
    </vue-tabs>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */
import { VueTabs, VTab } from 'vue-nav-tabs';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import HistoryList from '@/components/downloadhistory/historyList.vue';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'history-list': HistoryList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // dml status
    this.$store.dispatch('dml/refreshStatus');
  },
  methods: {
    tabChangeHandler(tabIndex, newTab) {
      // using tab-change event available in vue-nav-tabs: http://vuejs.creative-tim.com/vue-tabs/#/
      this.$analytics.trackEvent('Download History', newTab.title);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    slotsUsed() {
      return this.$store.getters['dml/slotsUsed'];
    },
    slotsFree() {
      return this.$store.getters['dml/slotsFree'];
    },
    slotsTotal() {
      return this.slotsUsed + this.slotsFree;
    },
    dmlRatio() {
      const ratio = (this.slotsFree / this.slotsTotal) * 100;
      return 100 - Math.round(ratio); // remove the 100 subtraction if we want an empty bar to mean "no downloads left"
    },
  },
};
</script>

<style lang="scss" scoped>
.member-info-dml {
  height: 3px;
}
</style>
